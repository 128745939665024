import React, { useState } from 'react';
import searching from '@/assets/banner.png';
import './newLanding.css';
import { useNavigate } from 'react-router-dom';
import { SearchCategory } from './SearchTabContent/SearchCategory';
import mouseIcon from '@/assets/mouseIcon.png';
import roundedBg from '@/assets/roundedBg.png';
import shape from '@/assets/s-right.png';
import gpay from '@/assets/g-pay.png';
import astore from '@/assets/a-store.png';
import { SearchTabForm } from './SearchTabContent/SearchTabForm';
import { dropRight } from 'lodash';

export const SearchTabs = ({ setApply }) => {
  const [activeCategory, setActiveCategory] = useState('House Rent'); // Default category
  const [searchVal, setSearchVal] = useState('');
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <section className="new-search relative text-white ">
      <div className="w-100">
        <div
          className="search-content-bg rounded "
          style={{ backgroundImage: 'url(' + searching + ')' }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="content-wrapper col-md-6 pt-5">
                <span className="ticker-label"></span>
                <button className="wlcm-text border-0">Welcome to the Reeipo</button>
                <h3 className="f-30 bold text-white mt-5">
                  Unlock Lucrative Real Estate Investments with Us
                </h3>
                <p className="f-14">
                  A platform where properties, projects and real estate opportunities meet
                  connections determined to see them succeed in today's dynamic markets.
                </p>
                <a href="#" className="link mt-4 d-block">
                  Start Explore
                </a>
              </div>
              <div className="top-searching-tabs pt-4 col-md-5 mb-4">
                <div className="inner-width-wrapper pb-0">
                  <p className="heading-wrapper">Use Quick Search</p>
                  <ul
                    className="nav nav-tabs justify-content-center border-0 gap-2"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'HouseRent' ? 'active' : ''}`}
                        onClick={() => handleCategoryClick('HouseRent')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'HouseRent'}
                      >
                        House Rent
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'BussinessSale' ? 'active' : ''}`}
                        onClick={() => handleCategoryClick('BussinessSale')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'BussinessSale'}
                      >
                        Commercialism
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'PropertyProject' ? 'active' : ''
                          }`}
                        onClick={() => handleCategoryClick('PropertyProject')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'PropertyProject'}
                      >
                        Property Project
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'RentProperty' ? 'active' : ''}`}
                        onClick={() => handleCategoryClick('RentProperty')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'RentProperty'}
                      >
                        Rent Property
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'InvestmentCompany' ? 'active' : ''
                          }`}
                        onClick={() => handleCategoryClick('InvestmentCompany')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'InvestmentCompany'}
                      >
                        Investment Company
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeCategory === 'SaleProperty' ? 'active' : ''}`}
                        onClick={() => handleCategoryClick('SaleProperty')}
                        type="button"
                        role="tab"
                        aria-selected={activeCategory === 'SaleProperty'}
                      >
                        Sale Property
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active">
                      <SearchCategory category={activeCategory} setApply={setApply} />
                      {/* <SearchTabForm category={activeCategory} setApply={setApply} /> */}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="banner-shape" style={{ backgroundImage: 'url(' + shape + ')' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-6 ms-auto">
                  <div className="join-text mt-5">
                    <h5>Join Us—Get the App Today!</h5>
                  </div>
                  <div className="g-pay d-flex align-items-center gap-3">
                    <div className="g-bg d-flex gap-2 align-items-center">
                      <div className="g-img">
                        <img src={gpay} alt="" />
                      </div>
                      <div className="g-text">
                        <p className='mb-0 fs-14'>Download on the</p>
                        <h6 className='mb-0 fs-14'>Google Play</h6>
                      </div>
                    </div>
                    <div className="g-bg d-flex gap-2 align-items-center">
                      <div className="g-img">
                        <img src={astore} alt="" />
                      </div>
                      <div className="g-text">
                        <p className='mb-0 fs-14'>Download on the</p>
                        <h6 className='mb-0 fs-14'>App Store</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-bottom-btn py-3 position-relative">
            <img src={roundedBg} alt="" className="position-absolute d-block w-100 bg-img" />
            <button className="d-flex gap-3 align-items-center mx-auto">
              <img src={mouseIcon} alt="" className="d-block w-100" />
              Scroll Down To Discover
            </button>
          </div>
        </div>
      </div>

    </section>
  );
};
