import { axios, axios2 } from '@/lib/axios';
import { API_URL } from '@/config';
import { RegisterResponse } from '../types';

export type VerifyRegisterOtpDTO = {
  otp: string;
  email: string;
};

export const verifyRegisterOtp = (data: VerifyRegisterOtpDTO): Promise<RegisterResponse> => {
  // return axios.post(`${API_URL}/customer-api/verify-email`, data);
  return axios2.post(`/customer-api/verify-email`, data);
};
