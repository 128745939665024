import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../header.css';
import toggle from '@/assets/menu1.png';
import i18next from 'i18next';

const MenuLinks = () => {
  const [showFlats, setshowFlats] = useState<boolean>(false);
  const [showFlatRent, setShowFlatRent] = useState<boolean>(false);
  const handleParams = () => {
    window.localStorage.setItem('filter_data', 'no');
    window.localStorage.setItem('company_type', 'Large');
  };
  const navigate = useNavigate();

  const flatSale = [
    { name: `${i18next.t('Apartment/Flat for Sale')}`, value: 'apartment_for_sale' },
    { name: `${i18next.t('Villa/House for Sale')}`, value: 'villa_house_for_sale' },
    { name: `${i18next.t('Townhouse')}`, value: 'Townhouse' },
    { name: `${i18next.t('Penthouse')}`, value: 'Penthouse' },
    { name: `${i18next.t('Residential Building')}`, value: 'Residential_Building' },
    { name: `${i18next.t('Residential Floor')}`, value: 'Residential_Floor' },
    { name: `${i18next.t('Villa Compound')}`, value: 'Villa_Compound' },
    { name: `${i18next.t('Commercial for Sale')}`, value: 'Commercial_for_Sale' },
    { name: `${i18next.t('Land for Sale')}`, value: 'Land_for_Sale' },
    { name: `${i18next.t('Multiple Units for Sale')}`, value: 'Multiple_units_for_Sale' },
  ];

  const flatRent = [
    { name: `${i18next.t('Apartment/Flat for rent')}`, value: 'apartment_for_rent' },
    { name: `${i18next.t('Villa/House for rent')}`, value: 'villa_house_for_rent' },
    { name: `${i18next.t('Townhouse')}`, value: 'Townhouse' },
    { name: `${i18next.t('Penthouse')}`, value: 'Penthouse' },
    { name: `${i18next.t('Residential Building')}`, value: 'Residential_Building' },
    { name: `${i18next.t('Residential Floor')}`, value: 'Residential_Floor' },
    { name: `${i18next.t('Villa Compound')}`, value: 'Villa_Compound' },
    { name: `${i18next.t('Commercial for rent')}`, value: 'Commercial_for_Rent' },
    { name: `${i18next.t('Rooms for Rent')}`, value: 'Rooms_for_Rent' },
    { name: `${i18next.t('Short Term (Daily)')}`, value: 'Short_term_monthly' },
    { name: `${i18next.t('Short Term (Monthly)')}`, value: 'Short_term_daily' },
  ];

  const handleSaleBtnCick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const propertyName = (e.target as HTMLButtonElement).value;
    navigate(`/property-for-sale?type=${propertyName}`);
  };

  const handleRentBtnCick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const propertyName = (e.target as HTMLButtonElement).value;
    navigate(`/property-for-rent?type=${propertyName}`);
  };

  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <img src={toggle} alt="menuicon" />
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto  mb-lg-0 ">
          <li className="nav-item dropdown">
            <Link className="nav-link" to="/user" role="button" aria-expanded="false">
              {i18next.t('HOME')}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {i18next.t('Projects')}
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" to="/property-project">
                  {i18next.t('Property Project')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/project-developer">
                  {i18next.t('Project developments')}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {i18next.t('Properties')}
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li onMouseOver={() => setshowFlats(true)} onMouseLeave={() => setshowFlats(false)}>
                <Link className="dropdown-item" to={`/property-for-sale`}>
                  {i18next.t('Property for sale')}
                </Link>
                {showFlats && (
                  <div className="sub-menu">
                    <ul>
                      {flatSale.map((item, index) => {
                        return (
                          <li key={index}>
                            <button value={item.value} onClick={handleSaleBtnCick}>
                              {item.name}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </li>

              <li
                onMouseOver={() => setShowFlatRent(true)}
                onMouseLeave={() => setShowFlatRent(false)}
              >
                <Link className="dropdown-item" to="/property-for-rent">
                  {i18next.t('Property for rent')}
                </Link>
                {showFlatRent && (
                  <div className="sub-menu">
                    <ul>
                      {flatRent.map((item) => {
                        return (
                          <li>
                            <button value={item.value} onClick={handleRentBtnCick}>
                              {item.name}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </li>
              <li>
                <Link className="dropdown-item" to="/housing-for-rent">
                  {i18next.t('House for rent')}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {i18next.t('Reeipo Services')}
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" to="/our-service">
                  {i18next.t('Our Services')}
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="/how-it-works"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {i18next.t('How it works')}
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" to="/investor?page=investor">
                  {i18next.t('Investors')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/property_developer?page=property_developer">
                  {i18next.t('Property Developer')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/project_type?page=project_type">
                  {i18next.t('Project Type')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/faq?page=faq">
                  {i18next.t('FAQS')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/sales_condition?page=sales_condition">
                  {i18next.t('Sales Condition')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/how_It_work?page=how_It_work">
                  {i18next.t('How it works')}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MenuLinks;
