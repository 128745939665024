import { ROLES } from '@/lib/authorization';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const LoginUser = () => {
  const navigate = useNavigate();
  return (
    <Layout title="Log in to your account">
      <LoginForm
        type={ROLES.CUSTOMER}
        onSuccess={(url) => {
          navigate(url);
          window.location.reload();
        }}
      />
    </Layout>
  );
};
