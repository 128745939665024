import * as z from 'zod';
import { Button, Link } from '@/components/Elements';
import { Form, InputField, CheckBoxField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import './layout.css';
import { ROLES } from '@/lib/authorization';
import img from '@/assets/new-logo.png';
import { useUserStore } from '@/stores/user';
import { commonLogin } from '../api/login';
import { useEffect, useState } from 'react';
import storage from '@/utils/storage';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import go from '@/assets/google.png';
import GoogleLoginButton from '@/components/Authentication/GoogleLoginButton';
import email from '@/assets/email.svg';
import gmail from '@/assets/gmail.svg';

const schema = z.object({
  email: z
    .string()
    .min(1, 'Please enter email address')
    .max(40, 'Cannot exceed 40 characters')
    .email('Please enter valid email address!'),
  password: z.string().min(1, 'Please enter password'),
  remember: z.boolean().optional(),
});

type LoginValues = {
  email: string;
  password: string;
  remember: boolean;
};

type LoginFormProps = {
  type: ROLES;
  onSuccess: (url: string) => void;
};

export const LoginForm = ({ onSuccess, type }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const fetchForm = storage.getFormData();

  const [roleAgency, setIsRoleAgency] = useState(false);

  useEffect(() => {
    if (type == ROLES.AGENCY) {
      setIsRoleAgency(true);
    }
  }, [type]);
  const navigate = useNavigate();
  const axiosUrl = process.env.REACT_APP_API_URL;

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          {/* <img src={img} width="70" alt="alttext" /> */}
          <img src={img} alt="logo" className="mx-auto " />
        </div>
        <div className="col-md-8">
          {/* {roleAgency ? (
            <Button onClick={() => navigate('/auth/login')}>Login as Customer</Button>
          ) : (
            <>
              <Button onClick={() => navigate('/auth/login-agency')}>Login as Agency</Button>
              <Button onClick={() => navigate('/auth/login-lawyer')}>Login as Lawyer</Button>
            </>
          )} */}
        </div>
      </div>
      {type === ROLES.CUSTOMER ? (
        <>
          <div className="sign-in-header d-flex justify-content-between align-items-end">
            <div className="sign-up-logo ">
              <h5 className="text-black fw-400 mt-4">
                Welcome to <span className="color-ff3 fw-600">Reeipo!</span>
              </h5>
            </div>
            <div className="have-account">
              <p>No Account ?</p>
              {/* <a href="#" className="color-ff3">
                Sign up
              </a> */}

              <Link to="/auth/register">{i18next.t('Sign Up')}</Link>
            </div>
          </div>

          <h2 className="text-black f-50 fw-500">Sign in</h2>
          {/* <div className="login-google bg-eb rounded-10 p-2 text-center my-4 py-3">
            <a href="#" className="text-black text-decoration-none color-23 ">
              <h5 className="fw-400 d-flex align-items-center justify-content-center gap-3 mb-0">
                {' '}
                <span className="ps-2">
                  <img src={go} className="img-fluid " />
                </span>
                Sign in with Google
              </h5>
            </a>
          </div> */}

          <div className="social-accounts">
            <div className="d-flex">
              <img alt="banner" src={gmail} width="24" className="mr-5" />

              <GoogleLoginButton />
            </div>
          </div>

          {/* <h1 className="mb-0 mt-3"> {i18next.t('Hello')},</h1>
          <h1 className="text-primary mb-3">{i18next.t('welcome')}!</h1> */}
        </>
      ) : type === ROLES.ADMIN ? (
        <>
          <h1 className="my-5">{i18next.t('Welcome')}</h1>
          <h1 className="text-primary mb-3">Admin !</h1>
        </>
      ) : (
        <>
          {type === ROLES.AGENCY ? (
            <>
              <h1 className="mt-2">{i18next.t('Welcome')}</h1>
              <h1 className="text-primary mb-3">{i18next.t('Agency')} !</h1>
            </>
          ) : (
            <>
              <h1 className="mt-2">{i18next.t('Hello')}</h1>
              <h1 className="text-primary mb-3">{i18next.t('Lawyer')} !</h1>
            </>
          )}
        </>
      )}

      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          try {
            setLoading(true);
            const response = await commonLogin({ ...values, role: type });
            const { user } = response.data;
            const localData = {
              formValue: values,
              apiUrl: `${axiosUrl}/customer-api/login/`,
              apiType: 'post',
            };
            storage.setFormData(localData);
            if (type === ROLES.CUSTOMER) {
              if (!user?.email_verified) {
                storage.setEmail(values.email);
                onSuccess(`/auth/verify-otp`);
                return;
              }
              await login(response);
              if (!user?.isProfileCompleted) {
                onSuccess(`/user/complete-profile`);
                return;
              }
              if (!user?.phoneVerified) {
                useUserStore.getState().show();
              }
              if (response?.data?.user?.role == 'agency') {
                onSuccess(`/agency`);
              } else if (response?.data?.user?.role == 'lawyer') {
                onSuccess(`/lawyer`);
              } else {
                onSuccess(`/user`);
              }

              return;
            } else if (type === ROLES.AGENCY) {
              await login(response);
              if (response?.data?.user?.role == 'agency') {
                onSuccess(`/agency`);
              } else {
                onSuccess(`/`);
              }
            } else {
              await login(response);
              if (response?.data?.user?.role == 'lawyer') {
                onSuccess(`/lawyer`);
              } else {
                onSuccess(`/`);
              }
            }
          } finally {
            setLoading(false);
            // storage.clearFormData();
          }
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
          defaultValues: fetchForm?.formValue,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              wrapperClass="mt-2"
              label={i18next.t('Email Adress')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label={i18next.t('Password')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div className="d-flex justify-content-end login-flex">
              {/* <CheckBoxField
                  registration={register('remember')}
                  error={formState.errors['remember']}
                  label={i18next.t('Remember me')}
                /> */}
              {type === ROLES.ADMIN ? null : (
                <Link to="/auth/forget">{i18next.t('Forgot Password?')}</Link>
              )}
            </div>
            <div className="mt-2">
              <Button isLoading={loading} type="submit" className="w-full">
                {i18next.t('Sign in')}
              </Button>
            </div>
            {type === ROLES.ADMIN ? null : (
              <>
                {type === ROLES.AGENCY ? (
                  <p className="mt-4">
                    {i18next.t(`Don't have an account?`)}
                    <Link to="/auth/register-agency">
                      {i18next.t('Sign Up')} {i18next.t('as')} {i18next.t('Agency')}
                    </Link>
                  </p>
                ) : (
                  <>
                    {
                      type === ROLES.LAWYER ? (
                        <p className="mt-4">
                          {i18next.t(`Don't have an account?`)}
                          <Link to="/auth/register-lawyer">
                            {i18next.t('Sign Up')} {i18next.t('as')} {i18next.t('Lawyer')}
                          </Link>
                        </p>
                      ) : null
                      // <>
                      //   <p className="mt-4">
                      //     {i18next.t(`Don't have an account?`)}
                      //     <Link to="/auth/register">{i18next.t('Sign Up')}</Link>
                      //   </p>
                      // </>
                    }
                  </>
                )}
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
};
