import * as z from 'zod';
import { Button, Link } from '@/components/Elements';
import { Form, InputField, CheckBoxField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import './layout.css';
import { ROLES } from '@/lib/authorization';
import img from '@/assets/new-logo.png';
import simg from '@/assets/sign-img.png';
import em from '@/assets/email-img.png';
import go from '@/assets/google.png';
import { useUserStore } from '@/stores/user';
import { commonLogin } from '../api/login';
import { useEffect, useState } from 'react';
import storage from '@/utils/storage';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

type LoginValues = {
  email: string;
  password: string;
  remember: boolean;
};

type LoginFormProps = {
  type: ROLES;
  onSuccess: (url: string) => void;
};

export const NewLoginForm = () => {
  return (
    <div className="main-container">
      <div className="sign-up-welcome">
        <div className="container-fluid pe-md-5">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="welcom-info border-e bg-f8 text-center p-5 my-3 rounded-30 h-100 d-flex align-items-center justify-content-center flex-column">
                <div className="sign-up-w-logo mb-5">
                  <img src={img} alt="logo" className="mx-auto" />
                </div>
                <h5 className="text-black fw-400 mb-4">
                  Welcome to <span className="color-ff3 fw-600">Reeipo!</span>
                </h5>
                <div className="s-l-btn">
                  <div className="sign-btn mb-2">
                    <button type="button" className="btn">
                      Sign-up
                    </button>
                  </div>
                  <div className="login-btn">
                    <button type="button" className="btn  px-4">
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sign-up-form mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="sign-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="sign-up-header d-flex justify-content-between align-items-end">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="mx-auto " />
                    <h5 className="text-black fw-400 my-4">
                      Welcome to <span className="color-ff3 fw-600">Reeipo!</span>
                    </h5>
                  </div>
                  <div className="have-account">
                    <p>Have Account ?</p>
                    <a href="#" className="color-ff3">
                      Sign in
                    </a>
                  </div>
                </div>
                <div className="sign-up-content">
                  <h1 className="text-black">Sign up</h1>
                  <div className="login-google bg-eb rounded-10 p-2 text-center mt-5 py-3">
                    <a href="#" className="text-black text-decoration-none color-23 ">
                      <h5 className="fw-400 d-flex align-items-center justify-content-center gap-3 mb-0">
                        {' '}
                        <span className="ps-2">
                          <img src={go} className="img-fluid " />
                        </span>
                        Sign in with Google
                      </h5>
                    </a>
                  </div>
                  <div className="s-form mt-4">
                    <form>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="name mb-3">
                            <label className="form-label fw-400 text-black mb-0">First Name</label>
                            <InputField type="text" wrapperClass="mt-1" label="Name" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name mb-3">
                            <label className="form-label fw-400 text-black mb-0">Last Name</label>
                            <InputField type="text" wrapperClass="mt-1" label="Last name" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="email mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your email address
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="email address" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="comp-input mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your Company Name
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="Company Name" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="pasword-input mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your Password
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="Password" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="confirm-pasword-input mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your Confirm Password
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="Password" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="term-privacy-check">
                            <CheckBoxField
                              type="checkbox"
                              label={i18next.t('Terms & Conditions')}
                            />
                            <div className="sign-btn mt-4 text-center">
                              <button type="button" className="btn rounded-10 w-50 fw-500">
                                Sign-up
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>










      <div className="verified-page mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="veri-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="verify-up-header">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="v-logo " />
                  </div>
                </div>
                <div className="verify-content">
                  <div className="veri-heading">
                    <h4 className="mt-4 mb-3 text-black">Verify Your Account</h4>
                    <p>
                      A fresh verification link has been sent to your email address. Please check
                      your inbox (and spam/junk folder, if necessary) to complete the verification
                      process.
                    </p>



                    <div className="resend-btn sign-btn mt-4 text-center mt-5">
                      <button type="button" className="btn rounded-10 w-50 fw-500">
                        Resend Code
                      </button>
                    </div>
                    <p className="fs-14 my-3 text-center">Resend in 0:45 sec</p>
                    <div className="or-text text-center">
                      <p className="fs-14 w-50 mx-auto">Or</p>
                    </div>
                    <div className="v-login-btn text-center">
                      <button type="button" className="btn px-4 text-black">
                        Login
                      </button>
                    </div>


                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>











      <div className="email-verified-page mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="e-veri-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="verify-up-header">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="v-logo " />
                  </div>
                </div>
                <div className="email-verify-content">
                  <div className="email-img-box text-center">
                    <img src={em} alt="email" className="mx-auto" />
                  </div>
                  <div className="email-heading">
                    <h4 className="mt-4 text-black mb-0">Email Verified Successfully!</h4>
                    <p>Your Email has been Verified successfully</p>
                    <div className="back-to-login-btn sign-btn mt-4 text-center ">
                      <button type="button" className="btn rounded-10 w-50 fw-500">
                        Back To Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sign-in-form mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="sign-in-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="sign-in-header d-flex justify-content-between align-items-end">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="mx-auto " />
                    <h5 className="text-black fw-400 my-4">
                      Welcome to <span className="color-ff3 fw-600">Reeipo!</span>
                    </h5>
                  </div>
                  <div className="have-account">
                    <p>No Account ?</p>
                    <a href="#" className="color-ff3">
                      Sign up
                    </a>
                  </div>
                </div>
                <div className="sign-in-content">
                  <h1 className="text-black">Sign in</h1>
                  <div className="login-google bg-eb rounded-10 p-2 text-center mt-5 py-3">
                    <a href="#" className="text-black text-decoration-none color-23 ">
                      <h5 className="fw-400 d-flex align-items-center justify-content-center gap-3 mb-0">
                        {' '}
                        <span className="ps-2">
                          <img src={go} className="img-fluid " />
                        </span>
                        Sign in with Google
                      </h5>
                    </a>
                  </div>
                  <div className="s-form mt-4">
                    <form>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="email mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your username or email address
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="email address" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="pasword-input mb-3">
                            <label className="form-label fw-400 text-black mb-0">
                              Enter your Password
                            </label>
                            <InputField type="text" wrapperClass="mt-1" label="Password" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="forget-pass text-end">
                            <a href="#" className="color-42">
                              Forgot Password
                            </a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="sign-in sign-btn mt-4 text-center mt-5">
                            <button type="button" className="btn rounded-10 w-50 fw-500">
                              Sign-in
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reset-pass-page mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="re-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="re-up-header">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="v-logo " />
                  </div>
                </div>
                <div className="reset-content">
                  <div className="re-heading">
                    <h4 className="mt-4 mb-3 text-black">Reset your password</h4>
                    <div className="email mb-3">
                      <label className="form-label fw-400 text-black mb-0">
                        Enter your email address
                      </label>
                      <InputField type="text" wrapperClass="mt-1" label="email address" />
                    </div>
                    <div className="resend-btn sign-btn mt-4 text-center mt-5">
                      <button type="button" className="btn rounded-10 w-50 fw-500">
                        Resend Code
                      </button>
                    </div>
                    <p className="fs-14 my-3 text-center">Resend in 0:45 sec</p>
                    <div className="or-text text-center">
                      <p className="fs-14 w-50 mx-auto">Or</p>
                    </div>
                    <div className="v-login-btn text-center">
                      <button type="button" className="btn px-4 text-black">
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-pass-page mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="re-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="re-up-header">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="v-logo " />
                  </div>
                </div>
                <div className="new-pass-content">
                  <div className="re-heading">
                    <h4 className="mt-4 mb-3 text-black">Create New Password</h4>
                    <div className="pasword-input mb-3">
                      <label className="form-label fw-400 text-black mb-0">
                        Enter your Password
                      </label>
                      <InputField type="text" wrapperClass="mt-1" label="Password" />
                    </div>
                    <div className="pasword-input mb-3">
                      <label className="form-label fw-400 text-black mb-0">
                        Enter your Password
                      </label>
                      <InputField type="text" wrapperClass="mt-1" label="Password" />
                    </div>
                    <div className="reset-pass-btn sign-btn mt-4 text-center mt-5">
                      <button type="button" className="btn rounded-10 w-50 fw-500">
                        Reset Password
                      </button>
                    </div>
                    <div className="or-text text-center mt-4">
                      <p className="fs-14 w-50 mx-auto">Or</p>
                    </div>
                    <div className="v-login-btn text-center">
                      <button type="button" className="btn px-4 text-black">
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="password-verified-page mt-5">
        <div className="container-fluid pe-md-5">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 ps-0">
              <div className="sign-up-img">
                <img src={simg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="p-veri-up-info  border-e bg-f8 p-5 my-3 rounded-30 ">
                <div className="pass-up-header">
                  <div className="sign-up-logo ">
                    <img src={img} alt="logo" className="v-logo " />
                  </div>
                </div>
                <div className="password-verify-content">
                  <div className="pass-img-box text-center">
                    <img src={em} alt="email" className="mx-auto" />
                  </div>
                  <div className="pass-heading">
                    <h4 className="mt-4 text-black mb-0">Password Changed Successfully!</h4>
                    <p>Your password has been changed successfully.</p>
                    <div className="back-to-login-btn sign-btn mt-4 text-center ">
                      <button type="button" className="btn rounded-10 w-50 fw-500">
                        Back To Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
