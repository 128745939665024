import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../user.css';
import { Image } from '@/components/Elements';
// import house from '@/assets/house.png';
import icon1 from '@/assets/icon1.png';
import icon2 from '@/assets/icon2.png';
import icon3 from '@/assets/icon3.png';
import pay1 from '@/assets/pay1.png';
import { Property } from '@/types';
import { changeFav } from '@/api/propertyForSale/changeFav';
import FileViewer from '@/components/Viewer/FileViewer';
import plus from '@/assets/plus.png';
import { deletePropertyItem } from '@/api/deletePropertyItem';
import { useNotificationStore } from '@/stores/notifications';
import { changeAllFav } from '@/api/changeAllFav';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Button } from '@/components/Elements/Button';
import { formatPrice, trimThirty } from '@/utils/format';
import { useAuth } from '@/lib/auth';
import storage from '@/utils/storage';
import i18next from 'i18next';

const PropertyForSaleItem = ({
  item,
  headData,
  editIcon,
  deleteIcon,
  heartIcon,
  heartFavIcon,
  setHeartClicked,
  setDeleteClicked,
}: {
  deleteIcon: boolean;
  item: Property;
  headData: boolean;
  editIcon: boolean;
  heartFavIcon: boolean;
  heartIcon: boolean;
  setHeartClicked: any;
  setDeleteClicked: any;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleNavigate = () => {
    navigate(`/property-for-sale-detail/${item.id}?type=Propert_sale_rent`);
  };
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [favIcon, setFavIcon] = useState<boolean>(!!item.is_favourite);
  const localSymbol = storage.getSymbol();
  const localAmount = storage.getAmount();

  const typeVal = 'SaleProperty';
  const deleleItems = async (id: string, types: string) => {
    try {
      setButtonLoading(true);
      await deletePropertyItem(id, types);
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'Item deleted successfully!',
      });
    } finally {
      setDeleteClicked(true);
      setOpen(false);
      setButtonLoading(false);
    }
  };
  const deleleItemsSelect = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    deleleItems(item.id, typeVal);
  };
  const [open, setOpen] = useState(false);
  // const onOpenModal = () => setOpen(true);

  const onOpenModals = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  const changeStatusAction = async (id: string, accept: boolean) => {
    try {
      if (user) {
        await changeFav(id, accept);
        // setHeartClicked(true);
      } else {
        useNotificationStore.getState().addNotification({
          title: 'Warning',
          type: 'info',
          message: 'Please Login first!',
        });
        navigate('/auth/login');
      }
    } finally {
    }
  };
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFavIcon((val) => !val);
    changeStatusAction(item.id, false);
  };
  const handleClick2 = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeStatusAction(item.id, true);
    setFavIcon((val) => !val);
  };
  const handleEdit = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigate(`/user/add-property-for-sale?id=${item.id}&type=SaleProperty`);
  };
  const changeUnfavStatusAction = async (id: string, type: string) => {
    try {
      await changeAllFav(id, type);
    } finally {
      setHeartClicked(true);
    }
  };
  const handleUnfavourite = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeUnfavStatusAction(item.id, item.type);
    // refetch();
  };
  const [photo, setPhoto] = useState<any>();
  const [pics, setPics] = useState<any>();

  useEffect(() => {
    try {
      const evaluatedData = eval(item?.image_data);

      if (evaluatedData && evaluatedData.length > 0) {
        setPics(evaluatedData);
        setPhoto(evaluatedData[0]?.image);
      }
    } catch (error) {
      console.error('Error evaluating image_data:', error);
    }
  }, [item?.image_data]);

  // useEffect(() => {
  //   try {
  //     const evaluatedData = eval(item?.image_data);
  //     setPics(evaluatedData);

  //     if (item?.image_data && item?.image_data.length > 0 && pics) {
  //       setPhoto(pics[0]?.image);
  //     }
  //   } catch (error) {
  //     console.error('Error evaluating image_data:', error);
  //   }
  // }, [item?.image_data, pics]);

  function convertToInternationalCurrencySystem(labelValue: string) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(0) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + 'K'
      : Math.abs(Number(labelValue));
  }

  // const convertTomillions = convertToInternationalCurrencySystem(item?.price);

  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 home-prop-col">
        <button className="w-full" onClick={handleNavigate}>
          <div className="item-box border p-2 position-relative">
            <div>
              {/* <div className="head-functions2 ">
                <i className="fa-regular fa-heart"></i>
              </div> */}
              {headData == true ? (
                <div className="head-functions">
                  <span className="status">{i18next.t('Property For Sale')}</span>
                  <div className="d-flex icons">
                    {editIcon == true ? (
                      <span>
                        <button onClick={handleEdit}>
                          <i className="fa-solid fa-pen" />
                        </button>
                      </span>
                    ) : null}
                    {deleteIcon == true ? (
                      <span>
                        <button onClick={onOpenModals}>
                          <i className="fa-solid fa-trash" />
                        </button>
                      </span>
                    ) : null}
                    {heartIcon == true ? (
                      <div className=" fav-lists">
                        {favIcon ? (
                          <button onClick={handleClick}>
                            <i className="fa-solid fa-heart"></i>
                          </button>
                        ) : (
                          <button onClick={handleClick2}>
                            <i className="fa-regular fa-heart"></i>
                          </button>
                        )}
                      </div>
                    ) : null}
                    {heartFavIcon == true ? (
                      <div className=" fav-lists">
                        <button onClick={handleUnfavourite}>
                          <i className="fa-solid fa-heart"></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="head-functions2 ">
                  {favIcon ? (
                    <button onClick={handleClick}>
                      <i className="fa-solid fa-heart"></i>
                    </button>
                  ) : (
                    <button onClick={handleClick2}>
                      <i className="fa-regular fa-heart"></i>
                    </button>
                  )}
                </div>
              )}
              {/* 
              {item?.image_data?.length == 0 ? (
                <FileViewer file={plus ?? ''}></FileViewer>
              ) : (
                <Image
                  // imagefallback={eval(item?.image_data)[0]?.image}
                  className="itemImage"
                  alt="banner"
                  src={photo ?? item?.image_data}
                />
              )} */}

              {item?.image_data ? (
                <>
                  {item?.image_data.length == 0 ? (
                    <FileViewer file={plus ?? ''}></FileViewer>
                  ) : (
                    <Image
                      // imagefallback={eval(item?.image_data)[0]?.image}
                      className="itemImage"
                      alt="banner"
                      src={photo ?? ''}
                    />
                  )}
                </>
              ) : null}
              <div className="row sale-div">
                <div className="col-md-12 col-12">
                  <h5 className="mt-3 text-dark">{item.title}</h5>
                  <p className="text-sm stx left-kar">{trimThirty(item?.location)}</p>
                </div>
                {/* <div className="col-md-12 col-12 photoo">
                  <img src={pay1} alt="pay1" />
                </div> */}
              </div>
              <p className="text-sm dlr left-kar">
                {' '}
                {localSymbol} {formatPrice(item.price * localAmount)}
              </p>
              <div className="row">
                <div className="col-md-4 col-4 thy">
                  <img className="spic" src={icon1} alt="pay1" />{' '}
                  <span className="semibold">
                    {item.bathrooms} {i18next.t('Baths')}
                  </span>
                </div>
                <div className="col-md-4 col-4 thy">
                  <img className="spic" src={icon2} alt="pay1" />{' '}
                  <span className="semibold">
                    {item.bedrooms} {i18next.t('Beds')}
                  </span>
                </div>
                <div className="col-md-4 col-4 thy">
                  <img className="spic" src={icon3} alt="pay1" />{' '}
                  <span>
                    {item.size}
                    {i18next.t('sqFt')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>
        <Modal open={open} onClose={onCloseModal} center>
          <section className="model-datas">
            <h3>Are you sure you want to delete this item ?</h3>
          </section>

          <p className="btn-lines delete-bots">
            <Button isLoading={buttonLoading} onClick={deleleItemsSelect}>
              {i18next.t('Yes')}
            </Button>
            <Button onClick={onCloseModal}>{i18next.t('No')}</Button>
          </p>
        </Modal>
      </div>
    </>
  );
};

export default PropertyForSaleItem;
