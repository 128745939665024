import { useQuery } from 'react-query';
import { axios, axios2 } from '@/lib/axiosData';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Listing } from '@/types';

export const getPropertyProhectWebsite = ({
  min_price,
  max_price,
  location,
  bedroom_min,
  type,
  bedroom_max,
  amenities,
  filter_data,
  subscription_tyre,
  sort_price,
  sort_upload_date,
}: {
  min_price: string | null;
  max_price: string | null;
  location: string | null;
  bedroom_min: string | null;
  bedroom_max: string | null;
  amenities: string | null;
  filter_data: boolean;
  subscription_tyre: string;
  sort_price: string | null;
  type: any;
  sort_upload_date: string;
}): Promise<Listing[]> => {
  return axios.get(
    `/panel-api/get-property-project?filter_data=${filter_data}&type=${type}&subscription_tyre=${subscription_tyre}&sort_price=${sort_price}&min_price=${min_price}&max_price=${max_price}&location=${location}&bedroom_min=${bedroom_min}&bedroom_max=${bedroom_max}&amenities=${amenities}&sort_upload_date=${sort_upload_date}`
  );
};

type QueryFnType = typeof getPropertyProhectWebsite;

type UseListingOptions = {
  config?: QueryConfig<QueryFnType>;
  min_price: string | null;
  max_price: string | null;
  type: any;
  location: string | null;
  bedroom_min: string | null;
  bedroom_max: string | null;
  amenities: string | null;
  filter_data: boolean;
  subscription_tyre: string;
  sort_price: string | null;
  sort_upload_date: string;
};

export const usePropertyProjectListing = ({
  config,
  min_price,
  max_price,
  location,
  type,
  bedroom_min,
  bedroom_max,
  amenities,
  filter_data,
  subscription_tyre,
  sort_price,
  sort_upload_date,
}: UseListingOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['listing'],
    queryFn: () =>
      getPropertyProhectWebsite({
        min_price,
        max_price,
        location,
        bedroom_min,
        bedroom_max,
        amenities,
        filter_data,
        type,
        subscription_tyre,
        sort_price,
        sort_upload_date,
      }),
  });
};
