import React, { useEffect, useState } from 'react';
import { Button } from '@/components/Elements/Button';
import TextField from '@mui/material/TextField';
import '../newLanding.css';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import i18next from 'i18next';
import { SelectField } from '@/components/Form';
import { MenuItem, Select } from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import chevronDown from '@/assets/chevronDown.png';
import buildBlocks from '@/assets/buildBlocks.png';
import locationIcon from '@/assets/location.png';

export const SearchCategory = ({ category, setApply }: any) => {
  const navigate = useNavigate();
  const [minPrice, setMin] = useState<number | null>(null);
  const [maxPrice, setMax] = useState<number | null>(null);
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [address, setAddress] = useState<string>('');
  const [locationGoogle, setLocationGoogle] = useState();
  const [value, setValue] = useState(null);
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');

  const googleMapAPIkey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(Number(event.target.value));
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(Number(event.target.value));
  };

  const handleChangeLocation = async (value: any) => {
    const placeId = value.value.place_id;
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${googleMapAPIkey}`
    );
    const location = data?.results[0]?.geometry?.location;
    const addressData = data?.results[0]?.formatted_address;

    if (location) {
      setLocationGoogle(location);
      setValue(value);
    }
    if (addressData) {
      setAddress(addressData);
    }
  };

  useEffect(() => {
    if (locationGoogle) {
      setlatitude(locationGoogle?.lat);
      setlongitude(locationGoogle?.lng);
    }
  }, [locationGoogle]);

  const handlePropertyTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPropertyType(event.target.value);
  };

  const handleSubscriptionTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event, 'event------------->');
    setSubscriptionType(event.target.value);
  };

  const handleSubmit = () => {
    setApply({
      minPrice,
      maxPrice,
      location: address,
      propertyType,
      category,
      subscriptionType,
      latitude,
      longitude,
      disableSlider: true,
    });
  };

  // ------------------- Menu Bar -------------------
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <section className="search-tabs-form relative">
      <div className="container">
        <div className="selectBlocks">


          {!['PropertyProject', 'SaleProperty', 'RentProperty'].includes(category) && (
            <div className="select-tag-wrapper position-relative">
              <span className="selectIcon position-absolute">
                <img src={buildBlocks} alt="location" className="d-block w-100" />
              </span>
              <select
                name="selectProperty"
                id="selectProperty"
                value={propertyType}
                onChange={handlePropertyTypeChange}
              // className="form-select"
              >
                <option value="" disabled selected>
                  {i18next.t('Select Property Type')}
                </option>
                <option value="apartment_for_sale">{i18next.t('Apartment for sale')}</option>
                <option value="Villa_house_for_sale">{i18next.t('Villa/House for sale')}</option>
                <option value="Townhouse">{i18next.t('Townhouse')}</option>
                <option value="Penthouse">{i18next.t('Penthouse')}</option>
                <option value="Residential_Floor">{i18next.t('Residential Floor')}</option>
                <option value="Residential_Building">{i18next.t('Residential Building')}</option>
                <option value="Villa_Compound">{i18next.t('Villa Compound')}</option>
                <option value="Commercial_for_Sale">{i18next.t('Commercial for Sale')}</option>
                <option value="Land_for_Sale">{i18next.t('Land for Sale')}</option>
                <option value="Multiple_Units_for_Sale">
                  {i18next.t('Multiple Units for Sale')}
                </option>
              </select>
              <span className="chevDown position-absolute">
                <img src={chevronDown} alt="chevronDown" className="d-block w-100" />
              </span>
            </div>
          )}

          <div className="select-tag-wrapper position-relative">
            <span className="selectIcon position-absolute">
              <img src={buildBlocks} alt="location" className="d-block w-100" />
            </span>
            <select
              value={subscriptionType}
              onChange={handleSubscriptionTypeChange}
              name="subsciptionType"
              id="subsciptionType"
            // className="form-select"
            >
              <option value="" disabled selected>
                {i18next.t('Select Subscription Type')}
              </option>
              <option value="1">Basic</option>
              <option value="2">Standard</option>
              <option value="3">Premium</option>
            </select>
            <span className="chevDown position-absolute">
              <img src={chevronDown} alt="chevronDown" className="d-block w-100" />
            </span>
          </div>

          <div className="select-tag-wrapper position-relative range-tag-wrapper">
            <span className="text">Price Range</span>
            <input type="range" name="priceRange" id="priceRange" />
          </div>
          <div className="select-tag-wrapper position-relative button-tag-wrapper">
            <button className="searchbtn" type="button" onClick={handleSubmit}>
              Search
            </button>
          </div>

          {/* <div className="text-end">
            <button
              type="button"
              onClick={() => {
                setApply({
                  minPrice: '',
                  maxPrice: '',
                  location: '',
                  propertyType: '',
                  category: '',
                  subscriptionType: '',
                  latitude: '',
                  longitude: '',
                  disableSlider: false,
                });
              }}
              className="refresh-button p-0 bg-transparent border-0 justify-center"
            >
              <i className="fa-solid fa-rotate"></i>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};
