import { ROLES } from '@/lib/authorization';
import { useNavigate } from 'react-router-dom';
import img from '@/assets/new-logo.png';
import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';
import { Dialog } from '@/components/Elements';
import { useState } from 'react';
import { Button } from '@/components/Elements/Button';
import customer from '@/assets/customer.png';
import Agency from '@/assets/Agency.png';
import lawyer from '@/assets/lawyer.png';

export const Login = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setVal] = useState<string>();
  const closeDialog = () => {
    setIsOpen(false);
  };
  const setOpen = (val: string) => {
    setVal(val);
    setIsOpen(true);
  };

  // const handleLogin = (role:string) => {
  //   switch (role) {
  //     case 'customer':
  //       return '/auth/login-user';

  //     default:
  //       return '/auth/login';
  //       break;
  //   }
  // };

  const handleLogin = (role: string) => {
    switch (role) {
      case 'customer':
        if (value === 'login') {
          return '/auth/login-user';
        } else if (value === 'signup') {
          return '/auth/register';
        }
        break;
      case 'agency':
        if (value === 'login') {
          return '/auth/login-agency';
        } else if (value === 'signup') {
          return '/auth/register-agency';
        }
        break;
      case 'lawyer':
        if (value === 'login') {
          return '/auth/login-lawyer';
        } else if (value === 'signup') {
          return '/auth/register-lawyer';
        }
        break;
      default:
        return '/auth/login';
    }
    return '/auth/login';
  };

  return (
    <Layout title="Log in to your account">
      <>
        <div className="welcom-info border-e bg-f8 text-center p-5 my-3 rounded-30 h-100 d-flex align-items-center justify-content-center flex-column">
          <div className="sign-up-w-logo mb-5">
            <img src={img} alt="logo" className="mx-auto" />
          </div>
          <h5 className="text-black fw-400 mb-4">
            Welcome to <span className="color-ff3 fw-600">Reeipo!</span>
          </h5>
          <div className="s-l-btn">
            <div className="sign-btn mb-2">
              <button onClick={() => setOpen('signup')} type="button" className="btn">
                Sign-up
              </button>
            </div>
            <div className="login-btn">
              <button onClick={() => setOpen('login')} type="button" className="btn  px-4">
                Login
              </button>
            </div>
          </div>
        </div>{' '}
      </>
      {/* <LoginForm
        type={ROLES.CUSTOMER}
        onSuccess={(url) => {
          navigate(url);
          window.location.reload();
        }}
      /> */}

      <Dialog isOpen={isOpen} onClose={closeDialog}>
        <div className="inline-block align-top bg-white p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 rounded">
          <div className="row">
            <div className="col-12 mt-3 text-center text-dark">
              <h5>Please select</h5>
              <h5>Which user role would you like to fill?</h5>
              <div className="mt-5 d-flex gap-2 justify-content-center flex-md-row flex-column ">
                <div onClick={() => navigate(handleLogin('customer'))}>
                  <div className="customer-box shadow2 d-flex align-items-center gap-2 px-2 py-1 border rounded-10">
                    <span>
                      <img src={customer} alt="customer" />
                    </span>
                    <span>Customer</span>
                  </div>
                </div>
                <div onClick={() => navigate(handleLogin('agency'))}>
                  <div className="customer-box d-flex align-items-center gap-2 px-2 py-1  border rounded-10">
                    <span>
                      <img src={Agency} alt="agency" />
                    </span>
                    <span>Agency</span>
                  </div>
                </div>

                <div onClick={() => navigate(handleLogin('lawyer'))}>
                  <div className="customer-box d-flex align-items-center gap-2 px-2 py-1 border rounded-10">
                    <span>
                      <img src={lawyer} alt="lawyer" />
                    </span>
                    <span>Lawyer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
};
