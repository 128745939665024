import * as React from 'react';
import loginImage from '@/assets/signupbanner.png';
import loginImage1 from '@/assets/sign-img.png';
import { Head } from '@/components/Head';
import './layout.css';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import { ROLES } from '@/lib/authorization';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  image?: any;
  type?: ROLES;
};

export const Layout = ({ children, title, image, type = ROLES.CUSTOMER }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      {/* {type !== ROLES.ADMIN && <Header type={HeaderType.LANDING} />} */}
      <div className="container-fluid pe-md-5 auth-layout">
        <div className="row auth-lyot-rw">
          <div className="col-12 col-sm-6 ps-0">
            <div className="column d-flex align-items-center justify-content-center">
              <img src={image ?? loginImage1} alt="Login" className="layoutImg" />
            </div>
          </div>
          <div className="col-12 col-sm-5 mt-5 ml-3 auth-layot-right">{children}</div>
          <div></div>
        </div>
      </div>
    </>
  );
};
