import { axios, axios2 } from '@/lib/axios';
import { API_URL } from '@/config';

export type ResetDTO = {
  token: string;
  password: string;
};

export const resetPassword = (data: ResetDTO) => {
  return axios2.post(`customer-api/reset-password`, {
    new_password: data.password,
    token: data.token,
  });
};
