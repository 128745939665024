import { Table, TableColumn } from '@/components/Elements';
import data from './data.json';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from '@/components/Layout';
import { usePagination } from '@/hooks/usePagination';
import TableLoader from '@/components/Elements/Loader/TableLoader';

type Content = {
  id: string;
  page: string;
  page_name: string;
};

const ContentManagement = () => {
  const { page, changePage } = usePagination();

  const navigate = useNavigate();
  const columns: TableColumn<Content>[] = [
    { title: 'Page Name', field: 'page_name' },
    {
      title: 'Action',
      field: 'page',
      Cell({ entry: { id, page } }) {
        return (
          <>
            <span
              role="button"
              tabIndex={-1}
              onKeyDown={() => navigate(`/admin/edit-content/${id}?pageName=${page}`)}
              onClick={() => {
                if (id == '9' || id == '10' || id == '11') {
                  navigate(`/admin/role-content/${page}`);
                  // navigate(`/admin/faq-content`);
                } else if (id == '13') {
                  navigate(`/admin/categories-content`);
                } else if (id == '14') {
                  navigate(`/admin/services-content`);
                } else if (id == '5') {
                  navigate(`/admin/how-it-works`);
                } else {
                  navigate(`/admin/edit-content/${id}?pageName=${page}`);
                }
              }}
              className="icon me-2"
              title="Edit"
            >
              <i className="fa-solid fa-pencil"></i>
            </span>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <>
        <AdminLayout title="Edit Content Management">
          <Table<Content>
            data={data || []}
            columns={columns}
            page={parseInt(page)}
            total={data?.length ?? 0}
            changePage={changePage}
          />
        </AdminLayout>
      </>
    </div>
  );
};

export default ContentManagement;
