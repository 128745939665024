import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { RegisterAgency } from './RegisterAgency';
import { Forget } from './Forget';
import { ResetPassword } from './ResetPassword';
import { EmailVerify } from './EmailVerify';
import { LoginUser } from './LoginUser';
import { LoginAgency } from './LoginAgency';
import { RegisterLawyer } from './RegisterLawyer';
import { LoginLawyer } from './LoginLawyer';
import { AgencyEmailVerify } from './AgencyEmailVerify';
import { CompleteAgencyProfile } from './CompleteAgencyProfile';
import { NewLoginForm } from '../components/NewLoginForm';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="register-agency" element={<RegisterAgency />} />
      <Route path="register-lawyer" element={<RegisterLawyer />} />
      <Route path="login" element={<Login />} />
      <Route path="login-user" element={<LoginUser />} />

      <Route path="new-login" element={<NewLoginForm />} />

      <Route path="login-agency" element={<LoginAgency />} />
      <Route path="login-lawyer" element={<LoginLawyer />} />
      <Route path="forget" element={<Forget />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="verify-otp" element={<EmailVerify />} />
      <Route path="verify-email" element={<EmailVerify />} />
      <Route path="agency-verify-otp" element={<AgencyEmailVerify />} />
      <Route path="complete-agency-profile" element={<CompleteAgencyProfile />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
