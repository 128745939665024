import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { InvestorContent } from '@/types/InvestorContent';
import { ApiResponse } from '@/types';
import pageType from '@/constants/contentManagement';

export const getContent = (): Promise<ApiResponse<InvestorContent>> => {
  switch (pageName) {
    case 'investor':
      return axios.get('/admin-api/investor-content-page');
    case pageType.project:
      return axios.get('/admin-api/project-content-page');
    case pageType.propertyDeveloper:
      return axios.get('/admin-api/property-content-page');
    case pageType.saleCondition:
      return axios.get('/admin-api/sale-condition-page');
    case pageType.howItWorks:
      return axios.get('/admin-api/how-it-work-content');
    case pageType.PropertySale:
      return axios.get('/admin-api/property-sale-rent-page');
    case pageType.businessForSale:
      return axios.get('/admin-api/bussiness-for-sale-page');
    case pageType.businessDeveloper:
      return axios.get('/admin-api/bussiness-developer-content');
    case pageType.faq:
      return axios.get('/admin-api/faq-listing');
    case pageType.PropertyProject:
      return axios.get('/admin-api/property-project-content');
    case pageType.Privacy_Policy:
      return axios.get('/admin-api/privacy-policy');
    case pageType.Terms_And_Conditions:
      return axios.get('/admin-api/terms-and-conditions');
    default:
      return axios.get('/admin-api/investor-content-page');
      break;
  }
};

type QueryFnType = typeof getContent;

type investorData = {
  config?: QueryConfig<QueryFnType>;
};

export const useContent = ({ config }: investorData = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['content'],
    queryFn: () => getContent(),
  });
};
