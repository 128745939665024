import React, { useState, useEffect } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import apart from '@/assets/apart.jpg';
import pent from '@/assets/pent.jpg';
import villa from '@/assets/villa.jpg';
import resi from '@/assets/resi.jpg';
import profile from '@/assets/man-new.jpg';
import building from '@/assets/backWal.png';
import './newLanding.css';
import { ContentLayout } from '@/components/Layout';
import { SearchTabs } from './SearchTabs';
import Slider from 'react-slick';
import { Dialog, Link } from '@/components/Elements';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/auth';
import { useNewLandingListing } from '@/api/landing/newLandingListing';
import DOMPurify from 'dompurify';
import { useNotificationStore } from '@/stores/notifications';
import { axios, axios2 } from '@/lib/axios';
import NewListingLoader from '@/components/Elements/Loader/NewListingLoader';
import './custom.css';
import logo1 from '@/assets/logo1.png';
import nbanner from '@/assets/n-banner.png';
import logo2 from '@/assets/logo2.png';
import logo3 from '@/assets/logo3.png';
import logo4 from '@/assets/logo4.png';
import about from '@/assets/about-img.png';
import prop from '@/assets/pro-project.png';
import pros from '@/assets/pro-s-1.png';
import serv from '@/assets/services.png';
import ander from '@/assets/anderson.png';
import star from '@/assets/star.png';
import why from '@/assets/why-img.png';
import w1 from '@/assets/w1.png';
import w2 from '@/assets/w2.png';
import w3 from '@/assets/w3.png';
import mloc from '@/assets/m-location.png';
import wl from '@/assets/w-lady.png';
import wc from '@/assets/w-call.png';
import wangle from '@/assets/w-angle-img.png';
import hw1 from '@/assets/hw1.png';
import hw2 from '@/assets/hw2.png';
import hw3 from '@/assets/hw3.png';
import hw4 from '@/assets/hw4.png';
import tafter from '@/assets/tafter.png';
import bbefore from '@/assets/bbefore.png';
import searchIcon from '@/assets/searchicon.png';
import PropertySaleRentList from '@/features/admin/components/PropertySaleRentList';
import { useLandingPage } from '@/api/landing/landingPage';
import { trimThirty, trimSixty } from '@/utils/format';
const stateFromHTML = require('draft-js-import-html').stateFromHTML;

export const NewHomePage = () => {
  const [listing, setListing] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const [shareLink, setShareLink] = useState('');
  const navigate = useNavigate();
  const [apply, setApply] = useState({});
  const [activeCategory, setActiveCategory] = useState('All');
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [filterData, setFilterData] = useState(false);
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL as string;
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [subscribe, setSubscribe] = useState('');
  const [disableSlider, setDisableSlider] = useState(false);

  const { data, isLoading, refetch, isFetching } = useLandingPage({
    type: type,
    filter_data: true,
    search_data: '',
    sub_category: subType,
    latitude: lat,
    longitude: lng,
    subscription_tyre: subscribe,
    min_price: min,
    max_price: max,
  });

  useEffect(() => {
    setType(apply.category);
    setSubType(apply.propertyType);
    setLat(apply.latitude);
    setLng(apply.longitude);
    setMin(apply.min_price);
    setMax(apply.max_price);
    setSubscribe(apply.subscriptionType);
    setDisableSlider(apply.disableSlider ?? false);
  }, [apply]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setSubType('');
    if (category === 'All') {
      setType('');
      setFilterData(false);
    } else {
      setType(category);
      setFilterData(true);
    }
  };

  const handleSubCategoryClick = (category) => {
    setActiveCategory(category);
    setType('');
    if (category === 'All') {
      setSubType('');
      setFilterData(false);
    } else {
      setSubType(category);
      setFilterData(true);
    }
  };

  useEffect(() => {
    setListing(data?.data);
  }, [data, isLoading]);

  useEffect(() => {
    refetch();
  }, [type, filterData, apply, subType]);

  const slidersettings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    // autoplaySpeed: 500,
  };
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: 'linear',
  };
  const filters = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const openDialog = (event, type, id) => {
    event.stopPropagation();
    const url = navigateToDetailPage(type, id);
    setShareLink(`${frontendUrl + url}`);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  const renderHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const navigateToDetailPage = (type, id) => {
    const routes = {
      BusinessSale: `/sale-detail/${id}?type=BusinessSale`,
      SaleProperty: `/property-for-sale-detail/${id}?type=SaleProperty`,
      RentProperty: `/property-for-rent-detail/${id}?type=RentProperty`,
      HouseRent: `/housing-detail/${id}`,
      InvestmentProject: `/investment-detail/${id}?type=InvestmentProject`,
      InvestmentCompany: `/company-detail/${id}?type=InvestmentCompany`,
      PropertyProject: `/property-detail/${id}?type=PropertyProject`,
    };

    return routes[type] || `/`;
  };

  const handleNavigation = (type, id) => {
    const url = navigateToDetailPage(type, id);
    navigate(url);
  };

  const handleFavorite = async (event, type, id, isFavourite) => {
    event.stopPropagation();

    if (!user) {
      useNotificationStore.getState().addNotification({
        title: 'Login',
        type: 'info',
        message: 'Need to Login first!',
      });
      navigate('/auth/login-agency');
      return;
    }

    const updatedListing = listing.map((item) =>
      item.id === id ? { ...item, is_favourite: !isFavourite } : item
    );
    setListing(updatedListing);

    const apiEndpoints = {
      BusinessSale: 'favourite-unfavourite-bussiness-sale',
      SaleProperty: 'favourite-unfavourite-sale-property',
      RentProperty: 'favourite-unfavourite-rent-property',
      HouseRent: 'favourite-unfavourite-house-rent',
      InvestmentProject: 'favourite-unfavourite-invest-project',
      InvestmentCompany: 'favourite-unfavourite-invest-company',
      PropertyProject: 'favourite-unfavourite-property-project',
    };

    const payloadKeys = {
      BusinessSale: 'business_id',
      SaleProperty: 'sale_id',
      RentProperty: 'rent_id',
      HouseRent: 'house_rent_id',
      InvestmentProject: 'invest_id',
      InvestmentCompany: 'invest_id',
      PropertyProject: 'property_project_id',
    };

    const apiUrl = `/panel-api/${apiEndpoints[type]}`;
    const payloadKey = payloadKeys[type];

    try {
      const response = await axios2.post(apiUrl, {
        [payloadKey]: id,
        status_action: !isFavourite,
      });

      useNotificationStore.getState().addNotification({
        title: `${!isFavourite ? 'Added Favourite' : 'Removed Favourite'}`,
        type: 'success',
        message: `Successfully ${!isFavourite ? 'added to' : 'removed from'} favourites`,
      });
    } catch (error) {
      console.error('Failed to update favorite status:', error);
      setListing(
        listing.map((item) => (item.id === id ? { ...item, is_favourite: isFavourite } : item))
      );
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'Failed to update favorite status. Please try again.',
      });
    }
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        useNotificationStore.getState().addNotification({
          title: 'Copied!',
          type: 'success',
          message: 'Link copied to clipboard.',
        });
      })
      .catch((err) => {
        console.error('Failed to copy link:', err);
      });
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % data?.category_data.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? data?.category_data.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <div className="header"></div>

      <ContentLayout title="New Landing">
        <Header type={HeaderType.LANDING} />
        <div className="search-sec">
          <SearchTabs setApply={setApply} />
        </div>
        <div className="landing-section-tabs space-tb">
          <div className="container">
            <div className="tabs-box">
              {!disableSlider && (
                <div className="main-searching-tabs gap-5 mb-5">
                  {/* <Slider {...filters}> */}
                  <div className="logo-list-wrapper d-flex py-2">
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'All' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('All')}
                        >
                          <img src={logo1} className="aprt-icon" alt="apartment" />
                          <h6 className="f-13 semi-bold">All</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'RentProperty' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('RentProperty')}
                        >
                          <img src={logo2} className="aprt-icon" alt="apartment" />
                          <h6 className="f-13 semi-bold">Rent Property</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'PropertyProject' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('PropertyProject')}
                        >
                          <img src={logo3} className="aprt-icon" alt="apartment" />
                          <h6 className="f-13 semi-bold">Property Project</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'SaleProperty' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('SaleProperty')}
                        >
                          <img src={logo4} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Sale Property</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'HouseRent' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('HouseRent')}
                        >
                          <img src={logo2} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">House Rent</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'InvestmentCompany' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('InvestmentCompany')}
                        >
                          <img src={logo4} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Investment Company</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'BusinessSale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleCategoryClick('BusinessSale')}
                        >
                          <img src={logo2} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Business Sale</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'apartment_for_sale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('apartment_for_sale')}
                        >
                          <img src={logo3} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Apartment for sale</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'villa_house_for_sale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('villa_house_for_sale')}
                        >
                          <img src={logo2} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Villa/House for sale</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'townhouse' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('townhouse')}
                        >
                          <img src={logo4} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Townhouse</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'penthouse' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('penthouse')}
                        >
                          <img src={logo2} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Penthouse</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Residential_Floor' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Residential_Floor')}
                        >
                          <img src={logo3} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Residential Floor</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Residential_Building' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Residential_Building')}
                        >
                          <img src={logo4} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Residential Building</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Villa_Compound' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Villa_Compound')}
                        >
                          <img src={logo3} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Villa Compound</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Commercial_for_Sale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Commercial_for_Sale')}
                        >
                          <img src={logo2} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Commercial for Sale</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Land_for_Sale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Land_for_Sale')}
                        >
                          <img src={logo4} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Land for Sale</h6>
                        </Button>
                      </div>
                    </div>
                    <div className="logo-slide">
                      <div
                        className={`cat-filter-categories text-center ${
                          activeCategory === 'Multiple_Units_for_Sale' ? 'active' : ''
                        }`}
                      >
                        <Button
                          className="cat-link p-0 bg-transparent border-0"
                          onClick={() => handleSubCategoryClick('Multiple_Units_for_Sale')}
                        >
                          <img src={logo3} className="aprt-icon" alt="villa" />
                          <h6 className="f-13 semi-bold">Multiple Units for Sale</h6>
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* </Slider> */}
                </div>
              )}
            </div>
            <div className="heading-wrapper-section d-flex justify-content-between align-items-center">
              <p className="heading">Properties</p>
              {/* <form action="">
                <div className="search-block position-relative">
                  <span className="searchIcon position-absolute">
                    <img className="d-block w-100" src={searchIcon} alt="search" />
                  </span>
                  <input type="text" name="search" id="search" placeholder="Search" />
                </div>
              </form> */}
            </div>

            <div className="residential-tab-cards">
              {isLoading || isFetching ? (
                <NewListingLoader />
              ) : (
                <div className="row">
                  {listing && listing?.length > 0 ? (
                    listing?.map((item: any, index: number) => {
                      const parseImageData = (data: string) => {
                        try {
                          const parsedData = eval(data);
                          if (Array.isArray(parsedData)) {
                            return parsedData;
                          }
                        } catch (e) {
                          console.log(e);
                        }
                        return [];
                      };

                      const imageData = parseImageData(item?.image_data);

                      return (
                        <div key={index} className="col-12 col-md-3 mb-4">
                          <div
                            className="resi-card-box"
                            onClick={() => handleNavigation(item.type, item.id)}
                          >
                            <div className="resi-card-icon mb-1 relative">
                              <Slider {...settings}>
                                {imageData.length > 0 ? (
                                  imageData.map((img, idx) => (
                                    <div key={idx} className="res-slider-icon">
                                      <img
                                        src={img?.image ? img?.image : building}
                                        alt={img?.name || 'Property Image'}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <div className="res-slider-icon">
                                    <img src={building} alt={'Property Image'} />
                                  </div>
                                )}
                              </Slider>
                              <div className="resi-popup">
                                <Button
                                  className="pop-btn bg-transparent border-0 p-0"
                                  onClick={(e) => openDialog(e, item.type, item.id)}
                                >
                                  <span className="bg-pop">
                                    <i className="fa-solid fa-share"></i>
                                  </span>
                                </Button>
                              </div>
                              <div className="resi-propname">
                                {activeCategory == 'All' && (
                                  <span className="f-14">{item?.type}</span>
                                )}
                              </div>
                              <div className="wish-popup">
                                <Button
                                  className="wish-btn bg-transparent border-0 p-0"
                                  onClick={(e) =>
                                    handleFavorite(e, item.type, item.id, item.is_favourite)
                                  }
                                >
                                  <span className="bg-pop">
                                    <i
                                      className={`fa-heart ${
                                        item.is_favourite ? 'fa-solid text-danger' : 'fa-regular'
                                      }`}
                                    ></i>
                                  </span>
                                </Button>
                              </div>
                            </div>
                            <div className="resi-content">
                              <p className="f-16 semi-bold mb-0 price">
                                ${item?.filter_price?.toLocaleString()}
                              </p>
                              <h4 className="semi-bold f-16 title">{item?.title}</h4>
                              <p
                                className="f-15 mb-0 location"
                                dangerouslySetInnerHTML={renderHTML(item?.location || '')}
                              ></p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {' '}
                      <center>NO DATA FOUND</center>{' '}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <section>
          <div className="about-us-sec mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="about-img h-100">
                    <img src={about} className="a-img h-100" alt="about" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="about-content text-end">
                    <h2 className="heading-color fw-600">About Us</h2>
                    <p className="heading-color">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus imperdiet sed
                      id elementum. Quam vel aliquam sit vulputate. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit. Cursus imperdiet sed id elementum. Quam vel
                      aliquam sit vulputate. Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit. Cursus imperdiet sed id elementum. Quam vel aliquam sit vulputate.{' '}
                    </p>
                    <div className="a-view-btn">
                      <button type="button" className="btn btn-black">
                        View All
                      </button>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-6">
                      <div className="k-user p-4 rounded-24">
                        <h4 className="">178K+</h4>
                        <p className="mb-0">
                          Lorem ipsum dolor amet, consectetur adipiscing elit. Faucibus in
                          libero.Lorem ipsum dolor amet,
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="k2-user p-4 rounded-24">
                        <h4 className="">132</h4>
                        <p className="mb-0">
                          Lorem ipsum dolor amet, consectetur adipiscing elit. Faucibus in
                          libero.Lorem ipsum dolor amet,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="pro-project-sec" style={{ backgroundImage: 'url(' + prop + ')' }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <h2 className="text-white mb-4">Categories</h2>
                  <h3 className="text-white fw-500">Property projects</h3>
                  <p className="text-white fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus imperdiet sed id
                    elementum. Quam vel aliquam sit vulputate. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus imperdiet sed id. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Cursus imperdiet sed id elementum. Quam vel
                    aliquam sit vulputate. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus imperdiet sed id.
                  </p>
                  <div className="a-explore-btn">
                    <button type="button" className="btn btn-black">
                      Explore
                    </button>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="prop-slider mt-3">
                    <div className="slider1">
                      <div className="inner ">
                        {/* Slider */}
                        <Slider {...slidersettings}>
                          {data?.category_data.map((item: any, index: number) => {
                            return (
                              <div key={index} className="w-75">
                                <div className="card-flex d-flex gap-2 justify-content-between align-items-center">
                                  <div className="prop-img">
                                    <img src={item?.image} alt="pay1" />
                                    <div className="prop-text">
                                      <h6 className="mb-0 text-white">{item?.title}</h6>
                                      <p
                                        className="mb-0 text-white fw-400"
                                        dangerouslySetInnerHTML={{
                                          __html: trimThirty(item?.description),
                                        }}
                                      >
                                        {/* {articleContent && <div dangerouslySetInnerHTML={{ __html: articleContent }} />} */}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="services-us-sec mb-5 mt-4">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="service-img">
                    <img src={serv} className="a-img h-50" alt="about" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="services-content text-end">
                    <h2 className="heading-color fw-600">Services</h2>
                    <p className="heading-color">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus imperdiet sed
                      id elementum. Quam vel aliquam sit vulputate. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit. Cursus imperdiet sed id elementum. Quam vel
                      aliquam sit vulputate. Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit. Cursus imperdiet sed id elementum. Quam vel aliquam sit vulputate.{' '}
                    </p>
                    <div className="a-view-btn">
                      <button type="button" className="btn btn-black">
                        View All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="number-box-sec mt-4">
                <div className="row-flex d-flex flex-lg-nowrap flex-wrap gap-3">
                  <div className="n-one p-3">
                    <h2 className="text-white">01</h2>
                    <h4 className="text-black">Title</h4>
                    <p className="text-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    </p>
                  </div>
                  <div className="n-two p-3">
                    <h2 className="text-white">02</h2>
                    <h4 className="text-black">Title</h4>
                    <p className="text-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    </p>
                  </div>
                  <div className="n-three p-3">
                    <h2 className="text-white">03</h2>
                    <h4 className="text-black">Title</h4>
                    <p className="text-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    </p>
                  </div>
                  <div className="n-four p-3">
                    <h2 className="text-white">04</h2>
                    <h4 className="text-black">Title</h4>
                    <p className="text-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    </p>
                  </div>
                  <div className="n-five p-3">
                    <h2 className="text-white">05</h2>
                    <h4 className="text-black">Title</h4>
                    <p className="text-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="need-help py-5 my-5">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="row">
                      <div className="col-md-6">
                        <h3 className="heading-color">Need help? Talk to our expert.</h3>
                        <p className="mb-0">
                          Talk to our experts or Browse through more properties.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="can-call-btn">
                          <div className="cont-btn d-flex gap-3">
                            <div className="ca-btn">
                              <button type="button" className="btn btn-primary">
                                Contact Us{' '}
                                <span>
                                  <i className="fa-solid fa-arrow-up text-black ps-2"></i>
                                </span>
                              </button>
                            </div>

                            <div className="nu-btn a-view-btn">
                              <button type="button" className="btn btn-black bg-black text-white">
                                <span>
                                  <i className="fa-solid fa-phone text-white pe-2"></i>{' '}
                                </span>
                                920 851 8585
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="how-it-works-sec py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="heading-color">How it works</h2>
                  <p className="w-75 mx-auto">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus in libero
                    risus mper habitant arcu eget. Et integer facilisi eget. Lorem ipsum dolor.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus in libero
                    risus mper habitant arcu eget. Et integer facilisi eget. Lorem ipsum dolor. */}
                    <div dangerouslySetInnerHTML={{ __html: data?.how_it_work?.page_content }} />
                  </p>
                </div>
                <div className="works-box mt-5">
                  <div className="row gy-4">
                    {data?.how_it_work.map((item: any, index: number) => {
                      return (
                        <div className="col-md-3">
                          <div className="o-box p-4">
                            <div className="o-img text-center">
                              <img src={item?.icon} alt="hw1" className="mx-auto" />
                              <h6 className="my-3 fw-600">{item?.title}</h6>
                              <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                              <span className="n1">{item?.step_number}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="col-md-3">
                      <div className="o-box p-4">
                        <div className="o-img text-center">
                          <img src={hw2} alt="hw1" className="mx-auto" />
                          <h6 className="my-3 fw-600">Submit Your Property</h6>
                          <p>
                            Contact us by calling, emailing or submitting the form online and tell
                            us a little more details about your house.
                          </p>
                          <span className="n1">02</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="o-box p-4">
                        <div className="o-img text-center">
                          <img src={hw3} alt="hw1" className="mx-auto" />
                          <h6 className="my-3 fw-600">Submit Your Property</h6>
                          <p>
                            Contact us by calling, emailing or submitting the form online and tell
                            us a little more details about your house.
                          </p>
                          <span className="n1">03</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="o-box p-4">
                        <div className="o-img text-center">
                          <img src={hw4} alt="hw1" className="mx-auto" />
                          <h6 className="my-3 fw-600">Submit Your Property</h6>
                          <p>
                            Contact us by calling, emailing or submitting the form online and tell
                            us a little more details about your house.
                          </p>
                          <span className="n1">04</span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="why-choose-us mt-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="why-img pe-5">
                    <img src={why} alt="why-img" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="why-text">
                    <h3 className="heading-color">Why Choose Us</h3>
                    <p className="heading-color ">
                      As the complexity of buildings to increase, the field of architecture.
                    </p>
                  </div>
                  <div className="managment-sec">
                    <div className="pro-mngmnt d-flex align-items-end gap-3 mt-5">
                      <img src={w1} alt="w1" />
                      <div className="mngmnt-text">
                        <h6>Property Management</h6>
                        <p>
                          Nullam sollicitudin blandit eros eu pretium. Nullammaximus ultricies
                          auctor.
                        </p>
                      </div>
                    </div>
                    <div className=" serv pro-mngmnt d-flex align-items-end gap-3 mt-5">
                      <img src={w2} alt="w1" />
                      <div className="mngmnt-text">
                        <h6>Mortgage Services</h6>
                        <p>
                          Nullam sollicitudin blandit eros eu pretium. Nullammaximus ultricies
                          auctor.
                        </p>
                      </div>
                    </div>
                    <div className="curr pro-mngmnt d-flex align-items-end gap-3 mt-5">
                      <img src={w3} alt="w1" />
                      <div className="mngmnt-text">
                        <h6>Currency Services</h6>
                        <p>
                          Nullam sollicitudin blandit eros eu pretium. Nullammaximus ultricies
                          auctor.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="m-location my-4">
                <img src={mloc} alt="location" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="we-are-sec bg-im-dark">
            <div className="container">
              <div className="row pt-5">
                <div className="col-md-3 pe-0">
                  <div className="lady-img">
                    <img src={wl} alt="lady" />
                  </div>
                </div>
                <div className="col-md-9 ps-0">
                  <div className="we-heading  pt-4">
                    <h4 className="text-white">We’re Providing the Best Real Estate Services</h4>
                    <p className="text-light">
                      Make a type specimen book. It has survived not onlyfive centuries, but also
                      the leap into.
                    </p>
                  </div>
                  <div className="w-call-img">
                    <div className="w-call d-flex gap-5 align-items-center">
                      <div className="w-c-icon d-flex align-items-end">
                        <img src={wc} alt="call" />
                        <div className="w-c-text">
                          <p className="text-white mb-0 border-end pe-5">Our Hot Line:</p>
                          <p className="text-white mb-0">(888) 972-7121</p>
                        </div>
                      </div>
                      <div className=" email w-c-icon d-flex align-items-end">
                        <img src={wc} alt="call" />
                        <div className="w-c-text">
                          <p className="text-white mb-0">Mail Us:</p>
                          <p className="text-white mb-0">info@gmail.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="reqest-btn a-view-btn ps-3 mt-4">
                      <button type="button" className="btn btn-black py-4">
                        REQUEST A FAIR CASH OFFER TODAY
                      </button>
                    </div>
                    <div className="angle-img">
                      <img src={wangle} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="review-sec mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="review">
                    <h5 className="text-black">Testimonials</h5>
                    <p>Aliquam lacinia diam quis lacus euismod</p>
                  </div>
                </div>

                <div className="review-slider mt-3">
                  <div className="slider2">
                    <div
                      id="carouselExampleAutoplaying"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="d-flex gap-2">
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12 shadow">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12 shadow">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12 shadow">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12 shadow">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="d-flex gap-2">
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12 ">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="d-flex gap-2">
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="box-1  p-3 mb-5 bg-body-tertiary rounded-12">
                              <div className="card-flex">
                                <h5>Great Work</h5>
                                <p className="my-3">
                                  “At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                  blanditiis praesentium voluptatum deleniti atque corrupti quos
                                  dolores et”
                                </p>
                                <div className="star-content border-bottom pb-3">
                                  <div className="d-flex">
                                    <img src={star} alt="pay1" />
                                  </div>
                                </div>
                                <div className="anderson mt-3 d-flex gap-2 align-items-center">
                                  <img src={ander} alt="pay1" />
                                  <div className="and-text">
                                    <h6 className=" mb-0">Anderson</h6>
                                    <p className="  mb-0">22 Aug 2024</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Dialog isOpen={isOpen} onClose={closeDialog}>
          <div className="inline-block align-top bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="share-exp-modal">
              <div className="row">
                <div className="col-12">
                  <div className="share-experience">
                    <h5 className="f-20 mb-0 text-black">Share this experience</h5>
                    <div className="share-img-col d-flex gap-4 align-items-center my-4">
                      <img src={profile} className="share-img-exp" />
                      <p className="f-15 mb-0">Go VIP with Kevin Hart</p>
                    </div>
                    <div className="experience-shares d-flex gap-3">
                      <Button type="button" className="exp-button" onClick={copyLinkToClipboard}>
                        <i className="fa-solid fa-copy me-3"></i> Copy Link
                      </Button>
                      <Button
                        type="button"
                        className="exp-button"
                        onClick={() =>
                          window.open(`mailto:?subject=Check this out&body=${shareLink}`, '_blank')
                        }
                      >
                        <i className="fa-regular fa-envelope me-3"></i> Email
                      </Button>
                      <Button
                        type="button"
                        className="exp-button"
                        onClick={() => window.open(`sms:?body=${shareLink}`, '_blank')}
                      >
                        <i className="fa-solid fa-comment me-3"></i> Messages
                      </Button>
                      <Button
                        type="button"
                        className="exp-button"
                        onClick={() => window.open(`https://wa.me/?text=${shareLink}`, '_blank')}
                      >
                        <i className="fa-brands fa-whatsapp me-3"></i> WhatsApp
                      </Button>
                      <Button
                        type="button"
                        className="exp-button"
                        onClick={() => window.open(`https://m.me/?link=${shareLink}`, '_blank')}
                      >
                        <i className="fa-brands fa-facebook-messenger me-3"></i> Messenger
                      </Button>
                      <Button
                        type="button"
                        className="exp-button"
                        onClick={() =>
                          window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`,
                            '_blank'
                          )
                        }
                      >
                        <i className="fa-brands fa-facebook me-3"></i> Facebook
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Footer />
      </ContentLayout>
    </>
  );
};
