import { useQuery } from 'react-query';
import { axios, axios2 } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ApiResponse, FaqContent } from '@/types';
import { contentManagementDTO } from './postFaq';

export const faqUserData = (): Promise<ApiResponse<FaqContent[]>> => {
  return axios.get(`/admin-api/faq-listing`);
};

type QueryFnType = typeof faqUserData;

type UseFaqOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useFaqData = ({ config }: UseFaqOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['faq-detail'],
    queryFn: () => faqUserData(),
  });
};

export const getAgencyFaqById = (id: string): Promise<ApiResponse<FaqContent>> => {
  return axios2.get(`/admin-api/agency-edit-faq?faq_id=${id}`);
};
