import React from 'react';
import img from '@/assets/new-logo.png';
import gmail from '@/assets/gmail.svg';
import email from '@/assets/email.svg';
import accounts from '@/assets/accounts.svg';
import { Dialog } from '@/components/Elements';
import { useDisclosure } from '@/hooks/useDisclosure';
import './auth.css';
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from '@/components/Authentication/GoogleLoginButton';
import i18next from 'i18next';

const SignInAccounts = ({ children }: { children: React.ReactElement }) => {
  const { isOpen, close, open } = useDisclosure(false);
  const navigate = useNavigate();

  const redirect = (url: string) => {
    close();
    navigate(url);
  };
  return (
    <div>
      <span tabIndex={-1} role="button" onKeyDown={open} onClick={open}>
        {children}
      </span>
      <Dialog size="large" isOpen={isOpen} onClose={close}>
        <div className="inline-block align-top login-acc bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="row">
            <div className="col-12 col-md-5">
              <img alt="banner" src={img} width="60" />
              <img alt="banner" src={accounts} className="w-100 ps-3" />
              <h3 className="text-center mt-5">Log in to post an ad !</h3>
            </div>
            <div className="col-12 col-md-7 mt-3">
              <h1>Create Account.</h1>
              <p>Hello there! To continue, we require your login credentials for account access</p>
              <div className="mt-4">
                <p className="text-bubbled">Continue with..</p>
                <div className="social-accounts">
                  <div className="d-flex">
                    <img alt="banner" src={gmail} width="24" className="mr-5" />

                    <GoogleLoginButton />
                  </div>

                  <div
                    tabIndex={-1}
                    role="button"
                    onKeyDown={() => redirect('/auth/login-user')}
                    onClick={() => redirect('/auth/login-user')}
                    className="d-flex align-items-center"
                  >
                    <img alt="banner" src={email} width="24" />
                    <p className="text-bubbled mb-0 ms-4">Continue with Email</p>
                  </div>
                </div>
                <p className="text-bubbled mt-3 mb-4">
                  Don&apos;t have an account?{' '}
                  <span
                    tabIndex={-1}
                    role="button"
                    onKeyDown={() => redirect('/auth/register')}
                    onClick={() => redirect('/auth/register')}
                    className="text-primary cursor-pointer"
                  >
                    {i18next.t('Sign Up')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SignInAccounts;
