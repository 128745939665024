import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvestmentCompany } from '@/types';
import '../user.css';
import { formatMoney, formatPrice, trimSixty, trimThirty } from '@/utils/format';
import { Image } from '@/components/Elements';
import plus from '@/assets/plus.png';
import { changeFav } from '@/api/companyInvestment/changeFav';
import FileViewer from '@/components/Viewer/FileViewer';
import { Modal } from 'react-responsive-modal';
import { Button } from '@/components/Elements/Button';
import { changeAllFav } from '@/api/changeAllFav';
import { deletePropertyItem } from '@/api/deletePropertyItem';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import storage from '@/utils/storage';
import i18next from 'i18next';

const InvestmentCompanys = ({
  item,
  headData,
  editIcon,
  deleteIcon,
  heartIcon,
  heartFavIcon,
  setHeartClicked,
  setDeleteClicked,
}: {
  deleteIcon: boolean;
  item: InvestmentCompany;
  headData: boolean;
  editIcon: boolean;
  heartFavIcon: boolean;
  heartIcon: boolean;
  setHeartClicked: any;
  setDeleteClicked: any;
}) => {
  const navigate = useNavigate();
  const localSymbol = storage.getSymbol();
  const localAmount = storage.getAmount();

  const [photo, setPhoto] = useState<any>();
  const [pics, setPics] = useState<any>();

  useEffect(() => {
    try {
      setPics(eval(item?.image_data));

      if (item?.image_data) {
        if (item.image_data.length === 0) {
          setPhoto(null);
        } else if (pics) {
          setPhoto(pics[0]?.image);
        }
      } else {
        setPhoto(null);
      }
    } catch (error) {
      console.error('Error evaluating image_data:', error);
      setPics([]);
      setPhoto(null);
    }
  }, [item.image_data, pics]);

  const [open, setOpen] = useState(false);
  // const onOpenModal = () => setOpen(true);

  const onOpenModals = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const [favIcon, setFavIcon] = useState<boolean>(!!item.is_favourite);
  const typeVal = 'InvestmentCompany';
  const deleleItems = async (id: string, types: string) => {
    try {
      setButtonLoading(true);
      await deletePropertyItem(id, types);
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'Item deleted successfully!',
      });
    } finally {
      setDeleteClicked(true);
      setOpen(false);
      setButtonLoading(false);
    }
  };

  const deleleItemsSelect = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    deleleItems(item.id, typeVal);
  };
  const changeUnfavStatusAction = async (id: string, type: string) => {
    try {
      await changeAllFav(id, type);
    } finally {
      setHeartClicked(true);
    }
  };
  const handleUnfavourite = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeUnfavStatusAction(item.id, item.type);
    // refetch();
  };

  const changeStatusAction = async (id: string, accept: boolean) => {
    try {
      if (user) {
        await changeFav(id, accept);
        setHeartClicked(true);
      } else {
        useNotificationStore.getState().addNotification({
          title: 'Warning',
          type: 'info',
          message: 'Please Login first!',
        });
        navigate('/auth/login');
      }
    } finally {
    }
  };
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFavIcon((val) => !val);
    changeStatusAction(item.id, false);
  };
  const handleClick2 = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeStatusAction(item.id, true);
    setFavIcon((val) => !val);
  };
  const handleEdit = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigate(`/user/company-money-investor?id=${item.id}&type=InvestmentCompany`);
  };
  const handleNavigate = () => {
    navigate(`/company-detail/${item.id}?type=InvestmentCompany`);
  };
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 home-prop-col">
        <button className="housingButton" onClick={handleNavigate}>
          <div className="item-box border p-2 position-relative">
            {headData == true ? (
              <div className="head-functions">
                <span className="status">{i18next.t('Investment Company')}</span>
                <div className="d-flex icons">
                  {editIcon == true ? (
                    <span>
                      <button onClick={handleEdit}>
                        <i className="fa-solid fa-pen" />
                      </button>
                    </span>
                  ) : null}
                  {deleteIcon == true ? (
                    <span>
                      <button onClick={onOpenModals}>
                        <i className="fa-solid fa-trash" />
                      </button>
                    </span>
                  ) : null}
                  {heartIcon == true ? (
                    <div className=" fav-lists">
                      {favIcon ? (
                        <button onClick={handleClick}>
                          <i className="fa-solid fa-heart"></i>
                        </button>
                      ) : (
                        <button onClick={handleClick2}>
                          <i className="fa-regular fa-heart"></i>
                        </button>
                      )}
                    </div>
                  ) : null}
                  {heartFavIcon == true ? (
                    <div className=" fav-lists">
                      <button onClick={handleUnfavourite}>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="head-functions2 ">
                {item.is_favourite == true ? (
                  <button onClick={handleClick}>
                    <i className="fa-solid fa-heart"></i>
                  </button>
                ) : (
                  <button onClick={handleClick2}>
                    <i className="fa-regular fa-heart"></i>
                  </button>
                )}
              </div>
            )}

            {item?.image_data?.length == 0 ? (
              <FileViewer file={plus ?? ''}></FileViewer>
            ) : (
              <Image
                // imagefallback={eval(item?.image_data)[0]?.image}
                className="itemImage"
                alt="banner"
                src={photo ?? item?.image_data}
              />
            )}

            {/* {item?.image_data ? (
              <>
                {item?.image_data.length == 0 ? (
                  <FileViewer file={plus ?? ''}></FileViewer>
                ) : (
                  <Image
                    // imagefallback={eval(item?.image_data)[0]?.image}
                    className="itemImage"
                    alt="banner"
                    src={photo ?? ''}
                  />
                )}
              </>
            ) : null} */}

            <h6 className="mt-3 text-dark">{item.company_name ?? '......'}</h6>
            {item?.location && <h6 className="mt-3 text-dark">{trimThirty(item.location)}</h6>}
            {item?.company_address && (
              <h6 className="mt-3 text-dark">{trimThirty(item.company_address)}</h6>
            )}
            <p className="text-sm stx">
              {item.company_email}
              {/* <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i> */}
              {/* <span>location : {item.location}</span> */}
            </p>
            {item.description && <p className="text-sm">{trimSixty(item.description)}</p>}
            <p className="text-sm asd">
              {' '}
              {i18next.t('Development')} | {i18next.t('Equity')}
            </p>
            <div className="greyTxt row">
              <p className="col-md-6 col-6 leftw">
                Remaining Share <br />
                {localSymbol} {formatPrice(item.shares_for_sale * localAmount)}
              </p>
              <p className="col-md-6 col-6 leftww">
                {i18next.t('Valuation')}
                <br />
                {localSymbol} {formatPrice(item.the_valuation * localAmount)}
              </p>
            </div>
            {/* <h6 className="mt-3 text-dark">{item.company_name}</h6>
          {location && (
            <p className="text-sm">
              <i className="fa-solid fa-map-location-dot"></i>
              {item.company_details}
            </p>
          )}
          <div className="tab">
            <p>{i18next.t('Valuation')}</p>
            <p>{formatMoney(item.the_valuation)}</p>
          </div>
          <div className="tab">
            <p>Share For Sale</p>
            <p>{formatMoney(item.shares_for_sale)}</p>
          </div>
          <div className="tab">
            <p>Remaining Share</p>
            <p>{formatMoney(item.remaninig_shares)}</p>
          </div>
          <div className="tab">
            <p>Minimum Drawing</p>
            <p>{formatPercent(item.minimum_drawing)}</p>
          </div> */}
          </div>
          <Modal open={open} onClose={onCloseModal} center>
            <section className="model-datas">
              <h3>Are you sure you want to delete this item ?</h3>
            </section>
            <p className="btn-lines delete-bots">
              <Button isLoading={buttonLoading} onClick={deleleItemsSelect}>
                {i18next.t('Yes')}
              </Button>
              <Button onClick={onCloseModal}>No</Button>
            </p>
          </Modal>
        </button>
      </div>
    </>
  );
};

export default InvestmentCompanys;
