import { axios, axios2 } from '@/lib/axios';

export type ForgtPasswordDTO = {
  email: string;
  base_url: string;
};

export const sendForgetPasswordLink = (data: ForgtPasswordDTO) => {
  return axios2.post('customer-api/forgot-password', data);
};
