import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../user.css';
import { formatMoney, formatPrice, trimSixty } from '@/utils/format';
import { Image } from '@/components/Elements';
import { changeFav } from '@/api/bussinessForSale/changeFav';
import { changeAllFav } from '@/api/changeAllFav';
import { Business } from '@/types';
import plus from '@/assets/plus.png';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Button } from '@/components/Elements/Button';
import { useNotificationStore } from '@/stores/notifications';
import { deletePropertyItem } from '@/api/deletePropertyItem';
import FileViewer from '@/components/Viewer/FileViewer';
import { useAuth } from '@/lib/auth';
import { usePersonStore } from '@/stores/currencyConverter';
import storage from '@/utils/storage';
import i18next from 'i18next';

const BusinessSale = ({
  item,
  headData,
  editIcon,
  deleteIcon,
  heartIcon,
  heartFavIcon,
  setHeartClicked,
  setDeleteClicked,
}: {
  deleteIcon: boolean;
  item: Business;
  headData: boolean;
  editIcon: boolean;
  heartFavIcon: boolean;
  heartIcon: boolean;
  setHeartClicked: any;
  setDeleteClicked: any;
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/sale-detail/${item.id}?type=BusinessSale`);
  };
  const localSymbol = storage.getSymbol();

  const [photo, setPhoto] = useState<any>();
  const [pics, setPics] = useState<any>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const localAmount = storage.getAmount();
  const [favIcon, setFavIcon] = useState<boolean>(!!item.is_favourite);
  // useEffect(() => {
  //   try {
  //     const evaluatedData = eval(item?.image_data);

  //     setPics(evaluatedData);

  //     if (item?.image_data && item?.image_data.length !== 0) {
  //       if (pics) {
  //         setPhoto(pics[0]?.image);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error occurred while evaluating image_data:', error);
  //   }
  //   // }, [item?.image_data, pics]);
  // }, []);

  useEffect(() => {
    try {
      const evaluatedData = eval(item?.image);
      setPics(evaluatedData);

      if (item?.image && item?.image.length > 0 && pics) {
        setPhoto(pics[0]?.image);
      }
    } catch (error) {
      console.error('Error evaluating image_data:', error);
    }
  }, [item?.image, pics]);

  const typeVal = 'BusinessSale';
  const deleleItems = async (id: string, types: string) => {
    try {
      setButtonLoading(true);
      await deletePropertyItem(id, types);
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'Item deleted successfully!',
      });
    } finally {
      setDeleteClicked(true);
      setOpen(false);
      setButtonLoading(false);
    }
  };
  const deleleItemsSelect = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    deleleItems(item.id, typeVal);
  };

  const [open, setOpen] = useState(false);
  // const onOpenModal = () => setOpen(true);

  const onOpenModals = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  const changeStatusAction = async (id: string, accept: boolean) => {
    try {
      if (user) {
        await changeFav(id, accept);
        setHeartClicked(true);
      } else {
        useNotificationStore.getState().addNotification({
          title: 'Warning',
          type: 'info',
          message: 'Please Login first!',
        });
        navigate('/auth/login');
      }
    } finally {
    }
  };
  // const changeStatusAction = async (id: string, accept: boolean) => {
  //   try {
  //     await changeFav(id, accept);
  //   } catch (error) {
  //     if (error.response && error.response.status === 200) {
  //       useNotificationStore.getState().addNotification({
  //         title: 'Success',
  //         type: 'success',
  //         message: 'Added to favourite successfully!',
  //       });
  //       alert('Added to favourite successfully!');
  //     } else {
  //       useNotificationStore.getState().addNotification({
  //         title: 'Login',
  //         type: 'info',
  //         message: 'Login first!',
  //       });
  //       alert('Login krle');
  //     }
  //   } finally {
  //     setHeartClicked(true);
  //   }
  // };

  const changeUnfavStatusAction = async (id: string, type: string) => {
    try {
      await changeAllFav(id, type);
    } finally {
      setHeartClicked(true);
    }
  };
  // const handleClick = (e: { stopPropagation: () => void }) => {
  //   e.stopPropagation();
  //   changeStatusAction(item.id, false);
  //   // refetch();
  // };
  // const handleClick2 = (e: { stopPropagation: () => void }) => {
  //   e.stopPropagation();
  //   changeStatusAction(item.id, true);
  //   // refetch();
  // };
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setFavIcon((val) => !val);
    changeStatusAction(item.id, false);
  };
  const handleClick2 = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeStatusAction(item.id, true);
    setFavIcon((val) => !val);
  };
  const handleUnfavourite = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    changeUnfavStatusAction(item.id, item.type);
    // refetch();
  };
  const handleEdit = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigate(`/user/create-business?id=${item.id}&type=BusinessSale`);
  };
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 home-prop-col">
        <button className="w-full" onClick={handleNavigate}>
          <div className="item-box border p-2 position-relative">
            {headData == true ? (
              <div className="head-functions">
                <span className="status">{i18next.t('Business For Sale')}</span>
                <div className="d-flex icons">
                  {editIcon == true ? (
                    <span>
                      <button onClick={handleEdit}>
                        <i className="fa-solid fa-pen" />
                      </button>
                    </span>
                  ) : null}
                  {deleteIcon == true ? (
                    <span>
                      <button onClick={onOpenModals}>
                        <i className="fa-solid fa-trash" />
                      </button>
                    </span>
                  ) : null}
                  {heartIcon == true ? (
                    <div className=" fav-lists">
                      {favIcon ? (
                        <button onClick={handleClick}>
                          <i className="fa-solid fa-heart"></i>
                        </button>
                      ) : (
                        <button onClick={handleClick2}>
                          <i className="fa-regular fa-heart"></i>
                        </button>
                      )}
                    </div>
                  ) : null}
                  {heartFavIcon == true ? (
                    <div className=" fav-lists">
                      <button onClick={handleUnfavourite}>
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="head-functions2 ">
                {item?.is_favourite == true ? (
                  <button onClick={handleClick}>
                    <i className="fa-solid fa-heart"></i>
                  </button>
                ) : (
                  <button onClick={handleClick2}>
                    <i className="fa-regular fa-heart"></i>
                  </button>
                )}
              </div>
            )}
            {/* 
            {item?.image_data?.length == 0 ? (
              <FileViewer file={plus ?? ''}></FileViewer>
            ) : (
              <Image
                // imagefallback={eval(item?.image_data)[0]?.image}
                className="itemImage"
                alt="banner"
                src={photo ?? item?.image}
              />
            )} */}

            {item?.image ? (
              <>
                {item?.image.length == 0 ? (
                  <FileViewer file={plus ?? ''}></FileViewer>
                ) : (
                  <Image
                    // imagefallback={eval(item?.image_data)[0]?.image}
                    className="itemImage"
                    alt="banner"
                    src={photo ?? ''}
                  />
                )}
              </>
            ) : null}

            <h6 className="mt-3 text-dark">{trimSixty(item.company_name)}</h6>
            <p className="text-left">{item?.location?.substring(0, 30)}..</p>
            <div className="tab">
              <p>{i18next.t('Rent price')} </p>
              {/* {item?.price && <p>{formatMoney(item.price)}</p>} */}
              {item?.rent && (
                <p>
                  {localSymbol} {formatPrice(item.rent * localAmount)}
                </p>
              )}
              {/* {item?.suggested_price && (
                <p>
                  {localSymbol} {formatPrice(item.suggested_price * localAmount)}
                </p>
              )} */}
            </div>
            <div className="tab">
              <p>Total Turnover</p>
              <p>
                {localSymbol} {formatPrice(item.total_turnover * localAmount)}
              </p>
            </div>
            {/* <div className="tab">
              <p>Operating Profit</p>
              <p>
                {localSymbol} {formatPrice(item.operating_profit * localAmount)}
              </p>
            </div> */}
          </div>
        </button>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <section className="model-datas">
          <h3>Are you sure you want to delete this item ?</h3>
        </section>
        <p className="btn-lines delete-bots">
          <Button isLoading={buttonLoading} onClick={deleleItemsSelect}>
            {i18next.t('Yes')}
          </Button>
          <Button onClick={onCloseModal}>No</Button>
        </p>
      </Modal>
    </>
  );
};

export default BusinessSale;
